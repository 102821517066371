<template>
  <div>
    <en-table-layout :tableData="pageData.data" :loading="loading">
      <template slot="toolbar">
        <p style="width: 100%;">提示：请审核通过后，导出需要提现的用户列表，然后到灵活用工平台上进行打款操作。打款中的数据导出3天后，状态将会自动变更为已完成状态。</p>
        <el-form-item label="状态">
          <el-select style="width:130px" size="medium" v-model="params.apply_status" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option v-for="(item,index) in list1" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
  
        <el-form-item label="申请时间">
          <el-date-picker size="medium" :default-time="['00:00:00', '23:59:59']" value-format="timestamp"
            style="width: 220px" v-model="order_time_range" type="daterange"
            range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            clearable />
        </el-form-item>
  
        <el-form-item>
          <el-input placeholder="请输入关键字搜索" size="medium" v-model.trim="keyword" clearable style="width: 280px">
            <el-select style="width: 120px" slot="prepend" size="medium" v-model="search_type">
              <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchEvent()">
            搜索
          </el-button>
          <el-button type="primary" size="small" :loading="importLoading" @click="downZd()">
            导出
          </el-button>
          <el-button type="primary" size="small" @click="handleHistory()">
            导出记录
          </el-button>
        </el-form-item>
      </template>
  
      <template slot="table-columns">
        <el-table-column label="编号" width="150" prop="apply_no" fixed="left" />
        <el-table-column label="用户ID" width="100" prop="member_id" fixed="left" />
        <el-table-column label="姓名" width="120" prop="name" fixed="left" />
        <el-table-column label="账号信息" prop="member_account" width="150" class-name="font-family-tttgb">
            <template slot-scope="{row}">
              <div v-if="row.member_mobile && row.member_account">
                <p>{{ row.member_mobile }}</p>
                {{ row.member_account }}
              </div>
              <div v-else>
                {{ row.member_mobile }}
                {{ row.member_account }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="提现手机号" width="120" prop="mobile" />
          <el-table-column label="身份证号" width="160" prop="id_card_number" />
          <el-table-column label="申请时间" width="150">
            <template slot-scope="scope">
              {{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="提现金额" min-width="100">
            <template slot-scope="scope">
              {{ scope.row.amount | unitPrice('￥') }}
            </template>
          </el-table-column>
          <el-table-column label="需打款金额" min-width="100">
            <template slot-scope="scope">
              {{ scope.row.revenue_amount | unitPrice('￥') }}
            </template>
          </el-table-column>
          <el-table-column label="审核人" min-width="150">
          <template slot-scope="scope">
            {{ scope.row.audit_user_name?scope.row.audit_user_name : '——' }}
          </template>
        </el-table-column>
        <el-table-column label="状态" props="apply_status" width="80">
          <template slot-scope="{row}">
            {{ apply_status_value(row.apply_status) }}
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="120" show-overflow-tooltip />
        <el-table-column label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" v-if="scope.row.apply_status === 0" @click="handleStockGoods(scope.row,1)">
              审核
            </el-button>
            <el-button size="mini" type="primary" @click="handleStockGoods(scope.row,2)">
              查看
            </el-button>
            <el-button size="mini" type="primary" @click="openRemarkDialog(scope.row)">
              备注
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="pageData.page_size" :total="pageData.data_total">
      </el-pagination>
    </en-table-layout>
    <!--审核 dialog-->
    <x-dialog :proxy="examinationDialog">
      <el-form class="orderDetail" style="padding:20px" :model="goodsAuditForm" ref="goodsAuditForm"
        label-width="110px">
        <el-form-item label="用户ID">{{ ordinaryInfo.member_id }}</el-form-item>
        <el-form-item label="用户姓名">{{ ordinaryInfo.name }}</el-form-item>
        <el-form-item label="用户账户信息">手机号：{{ ordinaryInfo.member_mobile }}&nbsp;&nbsp;&nbsp;账号：{{ ordinaryInfo.member_account }}</el-form-item>
        <el-form-item label="提现手机号">{{ ordinaryInfo.mobile }}</el-form-item>
        <el-form-item label="申请人身份证号">{{ ordinaryInfo.id_card_number }}</el-form-item>
        <el-form-item label="状态">{{ apply_status_value(ordinaryInfo.apply_status) }}</el-form-item>
        <el-form-item label="申请时间">{{ ordinaryInfo.create_time| unixToDate }}</el-form-item>
        <el-form-item label="申请提现金额">{{ ordinaryInfo.amount }}</el-form-item>
        <el-form-item label="提现手续费">{{ ordinaryInfo.commission }}</el-form-item>
        <el-form-item label="审核" prop="apply_status">
          <el-radio v-model="goodsAuditForm.apply_status" :label="1">通过</el-radio>
          <el-radio v-model="goodsAuditForm.apply_status" :label="4">拒绝</el-radio>
        </el-form-item>
        <el-form-item v-if="goodsAuditForm.apply_status === 4" label="拒绝原因" prop="audit_reject_remark">
          <el-input v-model.trim="goodsAuditForm.audit_reject_remark" type="textarea" :rows="5" maxlength="100"
            placeholder="拒绝原因最多不超过100字。" />
        </el-form-item>
      </el-form>
    </x-dialog>
    <!-- 充值详情 -->
    <el-dialog-x-dialog title="充值详情" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body
      width="650px" :visible.sync="messageVisible">
      <div style="padding:10px" class="messageTable">
        <el-form class="orderDetail" :model="ordinaryInfo" label-width="140px">
          <el-form-item label="用户ID">{{ ordinaryInfo.member_id }}</el-form-item>
          <el-form-item label="用户姓名">{{ ordinaryInfo.name }}</el-form-item>
          <el-form-item label="用户账户信息">{{ ordinaryInfo.member_account }}{{ ordinaryInfo.member_mobile }}</el-form-item>
          <el-form-item label="提现手机号">{{ ordinaryInfo.mobile }}</el-form-item>
          <el-form-item label="申请人身份证号">{{ ordinaryInfo.id_card_number }}</el-form-item>
          <el-form-item label="状态">{{ apply_status_value(ordinaryInfo.apply_status) }}</el-form-item>
          <el-form-item label="申请时间">{{ ordinaryInfo.create_time | unixToDate}}</el-form-item>
          <el-form-item label="申请提现金额">{{ ordinaryInfo.amount }}</el-form-item>
          <el-form-item label="提现手续费">{{ ordinaryInfo.commission }}</el-form-item>
          <el-form-item label="到账时间">{{ ordinaryInfo.revenue_time |unixToDate}}</el-form-item>
          <el-form-item label="实际到账金额">{{ ordinaryInfo.revenue_amount }}</el-form-item>
        </el-form>
      </div>
    </el-dialog-x-dialog>
    <exportHistory ref="exportHistory"></exportHistory>
    <!-- 备注 -->
    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入不超100字的备注信息" maxlength="100"
          v-model="remarkForm.remarks"></el-input>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import * as API_account from '@/api/account';
import { handleDownload } from '@/utils';
import { Foundation } from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import XDialog from "@/components/x-dialog/x-dialog";
import exportHistory from './components/distributionReviewHistory';
export default {
  name: 'distributionCashWithdrawApprove',
  components: { EnTableLayout, XDialog,exportHistory },
  data () {
    return {
      examinationDialog: $xDialog.create({
        title: "审核操作",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitGoodsAuditForm
      }),
      //审核操作
      goodsAuditForm: {
        apply_status:1
      },
      ordinaryInfo: {},//查看详情
      messageVisible: false,//详情弹窗
      remarkDialog: $xDialog.create({
        title: "备注信息",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editRemark
      }),
      // 备注信息
      remarkForm: {
        id: "",
        remarks: ""
      },
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],
      list1: [
        { value: 0, label: "申请中" },
        { value: 1, label: "打款中" },
        { value: 2, label: "已完成" },
        { value: 4, label: "提现失败" }
      ],
      list2: [
        { value: 'apply_no', label: '编号' },
        { value: 'member_id', label: '用户ID' },
        { value: 'name', label: '姓名' },
        { value: 'member_account', label: '账号信息' },
        { value: 'mobile', label: '提现手机号' },
        { value: 'id_card_number', label: '身份证号' },
      ],

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        apply_status:'',
      },
      keyword:'',
      search_type: "apply_no",
      order_time_range: [],
      /** 列表分页数据 */
      pageData: { data: [] },
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  activated () {
    this.GET_AccountList();
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /**备注信息打开 */
    openRemarkDialog (row) {
      this.remarkDialog.display();
      this.remarkForm.id = row.id; 
      this.remarkForm.remarks = ''; //订单备注
    },
    /**保存备注信息 */
    editRemark () {
      if (this.remarkForm.remarks === "" || this.remarkForm.remarks === null) {
        return this.$message.error("备注信息不能为空");
      }
      this.remarkForm.remarks = this.remarkForm.remarks.replace(/\n/g, "/n");
      API_account.getDistributionCashOutRemarks(this.remarkForm.id,this.remarkForm).then(res => {
        this.$message.success("保存成功");
        this.GET_AccountList();
      });
    },
    /**格式化状态 */
    apply_status_value (apply_status) {
      let obj = this.list1.find((value) => {
        return apply_status == value.value;
      });
      if (obj) {
        return obj.label;
      }
    },
    downZd () {
      this.importLoading = true;
      let params={
        ...this.params
      }
      if (this.keyword) {
        params[this.search_type] = this.keyword
      }
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          params.create_time_start =
            this.order_time_range[0] / 1000;
          params.create_time_end =
           this.order_time_range[1] / 1000 +
            86400;
        } else {
          params.create_time_start =
            this.order_time_range[0] / 1000;
          params.create_time_end =
            this.order_time_range[1] / 1000 + 86400;
        }
      }
      delete params.page_no;
      delete params.page_size;
      API_account.postDistributionCashOutImport(params).then((response) => {
        response.forEach((item) => {
          item.create_time = Foundation.unixToDate(
            item.create_time,
            'yyyy-MM-dd hh:mm:ss'
          );
          item.apply_status = this.list1.find((value, index, arr) => {
            return item.apply_status == value.value;
          }).label;
          item.member_account= (item.member_mobile?'手机号：'+item.member_mobile: '') + (item.member_mobile && item.member_account ? '\n' : '') + (item.member_account ? '账号：'+item.member_account : '')
        });
        let tHeaders = [
          '编号',
          '用户ID',
          '姓名',
          '账号信息',
          '提现手机号',
          '身份证号',
          '申请时间',
          '提现金额',
          '需打款金额',
          '审核人',
          '状态',
          '备注'
        ];
        let filterVals = [
          'apply_no',
          'member_id',
          'name',
          'member_account',
          'mobile',
          'id_card_number',
          'create_time',
          'amount',
          'revenue_amount',
          'audit_user_name',
          'apply_status',
          'remark',
        ];
        handleDownload(response, tHeaders, filterVals, '分销提现审核记录');
        this.importLoading = false;
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange1 (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange1 (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_AccountList();
    },

    GET_AccountList () {
      let params = {
        ...this.params
      }
      if (this.keyword) {
        params[this.search_type] = this.keyword
      }
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          params.create_time_start =
            this.order_time_range[0] / 1000;
          params.create_time_end =
           this.order_time_range[1] / 1000 +
            86400;
        } else {
          params.create_time_start =
            this.order_time_range[0] / 1000;
          params.create_time_end =
            this.order_time_range[1] / 1000 + 86400;
        }
      }
      this.loading = true;
      API_account.getDistributionCashOut(params).then((response) => {
        this.loading = false;
        this.pageData = response;
      });
    },
    /**操作 */
    handleStockGoods (row, type) { //type 1审核 2查看 
      //查询详情内容
      API_account.getDistributionCashOutInfo(row.id).then((res) => {
        this.ordinaryInfo = res;
        if (type == 1) {//审核
          this.goodsAuditForm= {
            apply_status:1
          }
          this.examinationDialog.display();
        } else {//查看
          this.messageVisible=true;
        }
      })
    },
    /** 审核表单提交 */
    submitGoodsAuditForm () {
      let { promise, resolve, reject } = Promise.withResolvers();
      const params = {
        ...this.ordinaryInfo,
        ...this.goodsAuditForm
      }
      API_account.postDistributionCashOutApprove(this.ordinaryInfo.id,params).then(() => {
        this.$message.success('保存成功！')
        this.GET_AccountList()
        resolve(true)
      }).catch(() => {
        reject(false)
      })
      return promise
    },
    /**历史记录 */
    handleHistory () {
      this.$refs.exportHistory.show();
    },
  }
};
</script>

<style lang="scss" scoped>
.orderDetail {
  .el-form-item {
    margin-bottom: 0;
  }
}</style>
